import { memo } from 'react';
import Spinner from 'components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import './LoadingApp.scss';


export default memo(function LoadingApp() {
    const { t } = useTranslation();
    return (
        <div id='loadingApp' className='center'>
            <div className='loading-app-section center'>
                <h2>{t("loading_page.message")}</h2>
                <Spinner />
            </div>
        </div>
    );
});