export const DEFAULT_SPINNER = {
    TYPE: 'default',
    SIZE: 'large',
    COLOR: 'white'
}

export const SPINNER_TYPES = {
    DEFAULT: 'default'
}

export const SPINNER_SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
}

export const SPINNER_COLORS = {
    WHITE: 'white',
    BLACK: 'black',
    GREY: 'grey'
}