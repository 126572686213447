// Import modules
import { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useStatistics } from 'hooks/useStatistics';

// Import store
import * as userSelectors from 'store/selectors/user';


export default memo(function Chronometer() {

    const [timeConnected, setTimeConnected] = useState(0);
    const username = useSelector(userSelectors.getName);
    const { statsTimeConnected } = useStatistics();

    useEffect(() => {
        let cpt = 0;
        const intervalId = setInterval(() => {
            setTimeConnected(cpt);
            cpt++;
        }, 1000);
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        const durationSave = 10; // 10 minutes
        if (username !== '' && timeConnected !== 0 && timeConnected % (60 * durationSave) === 0) {
            statsTimeConnected(durationSave);
        }
    }, [statsTimeConnected, username, timeConnected]);
});