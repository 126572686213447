import { createSlice } from '@reduxjs/toolkit'
 
const initialState_ = {
    isModalNotifications: false,
    nbNotifications: 0
}

const { actions, reducer } = createSlice({
    name: 'notifications',
    initialState: initialState_,
    reducers: {
        setModalNotifications: (state, action) => {
            state.isModalNotifications = action.payload
        },
        setNbNotifications: (state, action) => {
            state.nbNotifications = action.payload
        },
        reset: () => {
            return initialState_;
        }
    },
});

export const {
    setModalNotifications,
    setNotifications,
    setNbNotifications,
    addNotification,
    deleteNotification,
    reset
} = actions;

export default reducer;

/**

const notifications = [
    {category : "friend_request", message: "salut", updatedAt: 'Sun Jun 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}, 
    {category : "room_request", message: "salut", updatedAt: 'Sun Jun 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}
]; 
}

*/
