export const get = (state) => state.user;

export const getName = (state) => state.user.name;

export const getXp = (state) => state.user.xp;

export const getCreatedAt = (state) => state.user.createdAt;

export const getRights = (state) => state.user.rights;

export const getCountry = (state) => state.user.country;

export const getGender = (state) => state.user.gender;

export const getBio = (state) => state.user.bio;

export const getSuccess = (state) => state.user.success;

export const getMessage = (state) => state.user.message;

export const getAccountId = (state) => state.user.accountId;

export const getAvatar = (state) => state.user.avatar;