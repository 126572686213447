import { createSlice } from '@reduxjs/toolkit'
 
const initialState_ = {
    name              : '',
    nbUsersMin        : 0,
    nbUsersMax        : 8,
    isChatUsed        : false,
    isChatDisabled    : false,
    globals           : {}, 
    actors            : {}, 
    spectators        : {},
    timers            : {},
    images            : {},
    isModalActivitySettings : false
}

const { actions, reducer } = createSlice({
    name: 'activity',
    initialState: initialState_,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setNbUsersMin: (state, action) => {
            state.nbUsersMin = action.payload
        },
        setNbUsersMax: (state, action) => {
            state.nbUsersMax = action.payload
        },
        setChatUsed: (state, action) => {
            state.isChatUsed = action.payload
        },
        setChatDisabled: (state, action) => {
            state.isChatDisabled = action.payload
        },
        setGlobals: (state, action) => {
            state.globals = action.payload
        },
        setGlobal: (state, action) => {
            state.globals[action.payload.attribute] = action.payload.global
        },
        setActors: (state, action) => {
            state.actors = action.payload
        },
        setSpectators: (state, action) => {
            state.spectators = action.payload
        },
        setActor: (state, action) => {
            state.actors[action.payload.name] = action.payload.state
        },
        setSpectator: (state, action) => {
            state.spectators[action.payload.name] = action.payload.state
        },
        deleteActor: (state, action) => {
            delete state.actors[action.payload];
        },
        deleteSpectator: (state, action) => {
            delete state.spectators[action.payload];
        },
        setTimers: (state, action) => {
            state.timers = action.payload
        },
        setTimerIteration: (state, action) => {
            state.timers[action.payload.name] = action.payload.iteration
        },
        deleteTimer: (state, action) => {
            delete state.timers[action.payload];
        },
        deleteTimers: (state, action) => {
            state.timers = {};
        },
        setImages: (state, action) => {
            state.images = action.payload
        },
        setImage: (state, action) => {
            state.images[action.payload.name] = action.payload.image
        },
        setModalActivitySettings: (state, action) => {
            state.isModalActivitySettings = action.payload
        },
        reset: () => {
            return initialState_
        }
    },
});

export const {
    setName,
    setNbUsersMin,
    setNbUsersMax,
    setChatUsed,
    setChatDisabled,
    setGlobals,
    setGlobal,
    setActors,
    setSpectators,
    setActor,
    setSpectator,
    deleteActor,
    deleteSpectator,
    setTimers,
    setTimerIteration,
    deleteTimer,
    deleteTimers,
    setImages,
    setImage,
    setModalActivitySettings,
    reset
} = actions;

export default reducer;