export const AVATAR_IMAGES = {
    'avatar-male': './img/users/profile-man.png',
    'avatar-female': './img/users/profile-women.png'
}

export const AVATAR_IMAGES_ZOOMED = {
    'avatar-male': './img/users/profile-man-zoomed.png',
    'avatar-female': './img/users/profile-women-zoomed.png'
}

export const DEFAULT_AVATAR_ID = 'avatar-male';

export const DEFAULT_AVATAR_SIZE = 'extra-large';