export const TOAST_PROPERTIES = {
    position: "bottom-right",
    autoClose: 2000,
    pauseOnHover: false,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined,   
}; 

export const TOAST_PROPERTIES_LONG = {
    ...TOAST_PROPERTIES,
    autoClose: 10000,  
}; 

export const TOAST_PROPERTIES_FIX = {
    ...TOAST_PROPERTIES,
    autoClose: 20000,
}