export const ORIGIN_MODAL_USER = {
    ROOM: 'room',
    DEFAULT: 'default',
    FRIENDS: 'friends',
    DISCUSSION: 'discussion'
}

export const MODALS = {
    FRIENDS: 'friends',
    DISCUSSIONS: 'discussions',
    NOTIFICATIONS: 'notifications',
    LANGUAGE: 'language'
}