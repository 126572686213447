import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LANGUAGE } from 'constants/language'
 
const { actions, reducer } = createSlice({
    name: 'language',
    initialState: {
        isModalLanguage: false,
        language: DEFAULT_LANGUAGE
    },
    reducers: {
        setModalLanguage: (state, action) => {
            state.isModalLanguage = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        }
    },
});

export const { 
    setModalLanguage,
    setLanguage
} = actions;

export default reducer;