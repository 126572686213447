import { createSlice } from '@reduxjs/toolkit';
 
const initialState_ = {
    isModalFriends: false,
    nbFriendsRequestsNotRead: 0,
    friends: {},
    friendRequestsReceived: {},
    friendRequestsSent: {},
};

const { actions, reducer } = createSlice({
    name: 'friends',
    initialState: initialState_,
    reducers: {
        // Modal
        setModalFriends: (state, action) => {
            state.isModalFriends = action.payload;
        },
        
        // Notifications
        setNbFriendsRequestsNotRead: (state, action) => {
            state.nbFriendsRequestsNotRead = action.payload;
        },

        // Friends
        setFriends: (state, action) => {
            state.friends = action.payload
        },
        setFriendStatus: (state, action) => {
            state.friends[action.payload.name].status = action.payload.status;
        },
        setFriendsStatus: (state, action) => {
            action.payload.forEach(friend => {
                state.friends[friend.name].status = friend.status;
            });
        },
        addFriend: (state, action) => {
            state.friends[action.payload.name] = {
                date: action.payload.date, 
                image: action.payload.image,
                status: action.payload.status
            };
        },
        deleteFriend: (state, action) => {
            delete state.friends[action.payload];
        },

        // Friends requests received
        setFriendRequestsReceived: (state, action) => {
            state.friendRequestsReceived = action.payload;
        },
        addFriendRequestsReceived: (state, action) => {
            state.friendRequestsReceived[action.payload.name] = {
                date: action.payload.date,
                image: action.payload.image
            };
        },
        deleteFriendRequestsReceived: (state, action) => {
            delete state.friendRequestsReceived[action.payload];
        },

        // Friends requests sent
        setFriendRequestsSent: (state, action) => {
            state.friendRequestsSent = action.payload;
        },
        addFriendRequestsSent: (state, action) => {
            state.friendRequestsSent[action.payload.name] = {
                date: action.payload.date,
                image: action.payload.image
            };
        },
        deleteFriendRequestsSent: (state, action) => {
            delete state.friendRequestsSent[action.payload];
        },

        // Common
        reset: (state, action) => {
            return initialState_;
        }
    },
});

export const { 
    // Modal
    setModalFriends,

    // Notifications
    setNbFriendsRequestsNotRead,

    // Friends
    setFriends,
    setFriendsStatus,
    setFriendStatus,
    addFriend,
    deleteFriend,

    // Friends requests received
    setFriendRequestsReceived,
    addFriendRequestsReceived,
    deleteFriendRequestsReceived,

    // Friends requests sent
    setFriendRequestsSent,
    addFriendRequestsSent,
    deleteFriendRequestsSent,

    // Common
    reset
} = actions;

export default reducer;


// Temp data
// const friends = {
//     'joe' : {
//         status: 'online'
//     },
//     'rick' : {
//         status: 'online'
//     },
//     'john' : {
//         status: 'offline'
//     },
//     'mark' : {
//         status: 'online'
//     },
//     'james' : {
//         status: 'online'
//     },
//     'bryan' : {
//         status: 'offline'
//     }
// }
    