// Import modules
import React, { memo } from 'react';

// Import constants
import { DEFAULT_SPINNER } from 'constants/spinner';

// Import style
import './Spinner.scss';


export default memo(function Spinner ({ 
    type = DEFAULT_SPINNER.TYPE, 
    size = DEFAULT_SPINNER.SIZE,
    color = DEFAULT_SPINNER.COLOR
}) {
    return (
        <div id='spinner' className='center'>
            {
                <span
                    style={{ background: color }}
                    className={[
                        `loader-${type}`,
                        size
                    ].join(" ")}>  
                </span>
            }
        </div>
    );
});