import { createSlice } from '@reduxjs/toolkit'
 
const initialState_ = {
    isModalDiscussions: false,
    currentDiscussions: {},
    nbMessagesNotRead: 0
}

const { actions, reducer } = createSlice({
    name: 'discussions',
    initialState: initialState_,
    reducers: {
        setModalDiscussions: (state, action) => {
            state.isModalDiscussions = action.payload
        },
        addCurrentDiscussion: (state, action) => {
            state.currentDiscussions[action.payload.discussionName] = {
                messages: action.payload.messages,
                isHidden: action.payload.isHidden,
                avatar: action.payload.avatar,
                isBlocker: action.payload.isBlocker,
                isBlocked: action.payload.isBlocked
            };
        },
        deleteCurrentDiscussion: (state, action) => {
            delete state.currentDiscussions[action.payload];
        },
        hideCurrentDiscussion: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isHidden: true
                };
            }
        },
        showCurrentDiscussion: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isHidden: false
                };
            }
        },
        addMessage: (state, action) => {
            if (state.currentDiscussions[action.payload.discussionName]) {
                state.currentDiscussions[action.payload.discussionName] = {
                    ...state.currentDiscussions[action.payload.discussionName],
                    messages: [
                        ...state.currentDiscussions[action.payload.discussionName].messages,
                        action.payload.content
                    ]
                };
            }
        },
        markAsRead: (state, action) => {
            if (state.currentDiscussions[action.payload.discussionName]) {
                state.currentDiscussions[action.payload.discussionName] = {
                    ...state.currentDiscussions[action.payload.discussionName],
                    messages: state.currentDiscussions[action.payload.discussionName].messages.map((msg) => {
                        return {...msg, isRead: (msg.name === action.payload.senderName) ? true : msg.isRead};
                    })
                };
            }
        },
        setNbMessagesNotRead: (state, action) => {
            state.nbMessagesNotRead = action.payload;
        },
        blockUser: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isBlocked: true
                };
            }
        },
        blockByUser: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isBlocker: true
                };
            }
        },
        unblockUser: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isBlocked: false
                };
            }
        },
        unblockByUser: (state, action) => {
            if (state.currentDiscussions[action.payload]) {
                state.currentDiscussions[action.payload] = {
                    ...state.currentDiscussions[action.payload],
                    isBlocker: false
                };
            }
        },
        reset: () => {
            return initialState_;
        }
    },
});

export const {
    setModalDiscussions,
    addDiscussion,
    addCurrentDiscussion,
    deleteCurrentDiscussion,
    hideCurrentDiscussion,
    showCurrentDiscussion,
    setMessages,
    addMessage,
    markAsRead,
    setNbMessagesNotRead,
    blockUser,
    blockByUser,
    unblockUser,
    unblockByUser,
    reset
} = actions;

export default reducer;

/**

const discussions = {
    "John1": [
        {name : "John1", message: "salut", updatedAt: 'Sun Jun 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}, 
        {name : "mousqtr", message: "ça va", updatedAt: 'Sun Jun 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}, 
    ], 
    "Thierry": [
        {name : "Thierry", message: "salut", updatedAt: 'Sun Jun 26 2021 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}, 
        {name : "mousqtr", message: "ça va", updatedAt: 'Sun Jun 26 2021 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}
    ],
    "John2": [
        {name : "Thierry", message: "salut", updatedAt: 'Sun Feb 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}, 
        {name : "mousqtr", message: "ça va", updatedAt: 'Sun Feb 26 2022 09:42:11', createdAt: 'Sun Jun 26 2022 09:42:11', isRead: true}
    ], 
}

 */
