import { createSlice } from '@reduxjs/toolkit';
import { ROOM_STEPS } from 'constants/room';

const initialState_ = {
    step                 : ROOM_STEPS.NICKNAME_STEP,
    issue                : '',
    type                 : '',
    name                 : '',
    nickname             : '',
    title                : '',
    nbUsersMax           : 12,
    leader               : '',
    isModalRoomSettings  : false,
    isModalUsersSettings : false,
    urlLeaveRoom         : { page: '', section: '' }
}

const { actions, reducer } = createSlice({
    name: 'room',
    initialState: initialState_,
    reducers: {
        setStep: (state, action) => {
            state.step = action.payload
        },
        setIssue: (state, action) => {
            state.issue = action.payload
        },
        setType: (state, action) => {
            state.type = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setNickname: (state, action) => {
            state.nickname = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setNbUsersMax: (state, action) => {
            state.nbUsersMax = action.payload
        },
        setLeader: (state, action) => {
            state.leader = action.payload
        },
        setModalRoomSettings: (state, action) => {
            state.isModalRoomSettings = action.payload
        },
        setModalUsersSettings: (state, action) => {
            state.isModalUsersSettings = action.payload
        },
        setUrlLeaveRoom: (state, action) => {
            state.urlLeaveRoom = action.payload
        },
        reset: () => {
            return initialState_
        }
    },
});

export const {
    setStep,
    setIssue,
    setType,
    setName,
    setNickname,
    setTitle,
    setNbUsersMax,
    setLeader,
    setModalRoomSettings,
    setModalUsersSettings,
    setUrlLeaveRoom,
    reset
} = actions;

export default reducer;