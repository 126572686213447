// Import modules
import { useCallback } from 'react';
import { statisticAPI } from "api/users/StatisticAPI";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import store
import * as notificationsActions from 'store/features/notifications';
import * as notificationsSelectors from 'store/selectors/notifications';

// Import constants
import { TOAST_PROPERTIES } from "constants/toasts";

export function useStatistics () {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    
    const nbNotifications = useSelector(notificationsSelectors.getNbNotifications);

    /**
     * Add a statistic
     * @param {string} pField
     * @param {number} pValue
     */
    const add = useCallback((pField, pValue) => {
        return statisticAPI.add(pField, pValue).then((response) => {
            if (response.data.length > 0) {
                dispatch(notificationsActions.setNbNotifications(nbNotifications + response.data.length));
            }
        }).catch((error) => {
            console.error(error)
        });
    }, [dispatch, nbNotifications]);

    /**
     * Win a game of Mime
     */
    const winMime = useCallback(() => {
        return add('mime_win', 1).then(() => {
            toast.success(t("statistics.toast.mime_win"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Win a game of Pixels
     */
    const winPixels = useCallback(() => {
        return add('pixels_win', 1).then(() => {
            toast.success(t("statistics.toast.pixels_win"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Win a game of Quiz
     */
    const winQuiz = useCallback(() => {
        return add('quiz_win', 1).then(() => {
            toast.success(t("statistics.toast.quiz_win"), TOAST_PROPERTIES);
        });
    }, [t, add]);
    
    /**
     * Win a game of Morpio
     */
    const winMorpio = useCallback(() => {
        return add('morpio_win', 1).then(() => {
            toast.success(t("statistics.toast.morpio_win"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Win a game of Emojiz
     */
    const winEmojiz = useCallback(() => {
        return add('emojiz_win', 1).then(() => {
            toast.success(t("statistics.toast.emojiz_win"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Watch a youtube video
     */
    const watchYoutube = useCallback(() => {
        return add('youtube_nb_videos', 1).then(() => {
            toast.success(t("statistics.toast.youtube_nb_videos"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Create a room
     */
    const createRoom = useCallback(() => {
        return add('rooms_created', 1);
    }, [add]);

    /**
     * Join a room
     */
    const joinRoom = useCallback(() => {
        return add('rooms_joined', 1);
    }, [add]);

    /**
     * Send a room invitation
     */
    const invitationRoomSent = useCallback(() => {
        return add('invitations-room-sent', 1).then(() => {
            toast.success(t("statistics.toast.invitations_room_sent"), TOAST_PROPERTIES);
        });
    }, [t, add]);

    /**
     * Increment time connected
     */
    const timeConnected = useCallback((pDuration) => {
        return add('time_connected', pDuration);
    }, [add]);

    return {
        statsWinMime: winMime,
        statsWinPixels: winPixels,
        statsWinQuiz: winQuiz,
        statsWinMorpio: winMorpio,
        statsWatchYoutube: watchYoutube,
        statsWinEmojiz: winEmojiz,
        statsCreateRoom: createRoom,
        statsJoinRoom: joinRoom,
        statsInvitationRoomSent: invitationRoomSent,
        statsTimeConnected: timeConnected
    }
}