import { urlUsers, axiosInstance } from "api/config";

const URL_USER = urlUsers + '/statistic';

class StatisticAPI {
    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    add (pField, pValue) {
        return this.instance.post(this.url, {
            field: pField,
            value: pValue,
        });
    }

    get () {
        return this.instance.get(this.url);
    } 

    getAll () {
        return this.instance.get(this.url+'/all');
    }
}

export const statisticAPI = new StatisticAPI(axiosInstance, URL_USER);