export const DEVICE_TYPES = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    LAPTOP: 'laptop',
    SCREEN: 'screen'
}

export const DEVICE_MAX_WIDTHS = {
    MOBILE: 520,
    TABLET: 900,
    LAPTOP: 1312
}