import appReducer from './features/app';
import userReducer from './features/user';
import friendsReducer from './features/friends';
import discussionsReducer from './features/discussions';
import activityReducer from './features/activity';
import roomReducer from './features/room';
import usersReducer from './features/users';
import languageReducer from './features/language';
import notificationsReducer from './features/notifications';

import { configureStore } from '@reduxjs/toolkit';


const ENABLE_DEV_TOOLS = ['local', 'cypress'];
const devTools_ = ENABLE_DEV_TOOLS.includes(process.env.REACT_APP_ENV) ? true : false;


export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    friends: friendsReducer,
    discussions: discussionsReducer,
    notifications: notificationsReducer,
    activity: activityReducer,
    room: roomReducer,
    users: usersReducer,
    language: languageReducer
  },
  devTools: devTools_
})