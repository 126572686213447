import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Chronometer from 'components/Chronometer/Chronometer';
import store from './store/store';
import './i18n';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  <BrowserRouter forceRefresh={false}>
    <Provider store={store}>
      <Chronometer />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);
